// src/Header.js
import React from "react";

function Header() {
  return (
    <header className="header">
      <h1>Noorain Kazmi</h1>
      <p>Engineer / Web Developer / Software Developer / ML Developer / Data Scientist</p>
      <p>
        <a href="mailto:noorain@noorainkazmi.com">noorain@noorainkazmi.com</a> |{" "}
        <a href="https://github.com/noorains">GitHub</a>
      </p>
    </header>
  );
}

export default Header;
