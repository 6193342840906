// src/Skills.js
import React from 'react';
import { FaPython, FaJsSquare, FaCss3Alt, FaDocker, FaGithub, FaUbuntu, FaGitAlt, FaAws, FaReact, FaNodeJs } from 'react-icons/fa';
import { SiScikitlearn, SiPandas, SiNumpy, SiMysql, SiPostgresql, SiMongodb, SiKubernetes, SiAzuredevops, SiFastapi, SiStreamlit, SiJupyter, SiApachespark } from 'react-icons/si';

import './Skills.css';

function Skills() {
  return (
    <div className="skills-container">
      <h2>My Skills</h2>
      <div className="skills-grid">
        <div className="skill-item">
          <FaPython size={50} title="Python" />
          <p>Python</p>
          <div className="skill-experience">3 years of experience</div>
        </div>
        <div className="skill-item">
          <SiScikitlearn size={50} title="Scikit-Learn" />
          <p>Scikit-Learn</p>
          <div className="skill-experience">2 years with Scikit-Learn</div>
        </div>
        <div className="skill-item">
          <SiPandas size={50} title="Pandas" />
          <p>Pandas</p>
          <div className="skill-experience">2.5 years of data manipulation</div>
        </div>
        <div className="skill-item">
          <SiNumpy size={50} title="NumPy" />
          <p>NumPy</p>
          <div className="skill-experience">3 years in scientific computing</div>
        </div>
        <div className="skill-item">
          <FaJsSquare size={50} title="JavaScript" />
          <p>JavaScript</p>
          <div className="skill-experience">4 years in web development</div>
        </div>
        <div className="skill-item">
          <FaCss3Alt size={50} title="CSS" />
          <p>CSS</p>
          <div className="skill-experience">3 years styling web pages</div>
        </div>
        <div className="skill-item">
          <SiMysql size={50} title="MySQL" />
          <p>MySQL</p>
          <div className="skill-experience">2 years of database management</div>
        </div>
        <div className="skill-item">
          <SiPostgresql size={50} title="PostgreSQL" />
          <p>PostgreSQL</p>
          <div className="skill-experience">2 years with advanced SQL</div>
        </div>
        <div className="skill-item">
          <SiMongodb size={50} title="MongoDB" />
          <p>MongoDB</p>
          <div className="skill-experience">1.5 years in NoSQL databases</div>
        </div>
        <div className="skill-item">
          <FaGithub size={50} title="GitHub" />
          <p>GitHub</p>
          <div className="skill-experience">3 years of version control</div>
        </div>
        <div className="skill-item">
          <FaDocker size={50} title="Docker" />
          <p>Docker</p>
          <div className="skill-experience">2 years in containerization</div>
        </div>
        <div className="skill-item">
          <SiKubernetes size={50} title="Kubernetes" />
          <p>Kubernetes</p>
          <div className="skill-experience">1 year with container orchestration</div>
        </div>
        <div className="skill-item">
          <FaUbuntu size={50} title="Ubuntu" />
          <p>Ubuntu</p>
          <div className="skill-experience">3 years of Linux experience</div>
        </div>
        <div className="skill-item">
          <SiAzuredevops size={50} title="Azure" />
          <p>Azure</p>
          <div className="skill-experience">1 year with Azure services</div>
        </div>
        <div className="skill-item">
          <SiFastapi size={50} title="FastAPI" />
          <p>FastAPI</p>
          <div className="skill-experience">1 year in web API development</div>
        </div>
        <div className="skill-item">
          <SiStreamlit size={50} title="Streamlit" />
          <p>Streamlit</p>
          <div className="skill-experience">1 year creating data apps</div>
        </div>
        
        <div className="skill-item">
          <FaGitAlt size={50} title="Git" />
          <p>Git</p>
          <div className="skill-experience">3 years with Git</div>
        </div>
        <div className="skill-item">
          <SiJupyter size={50} title="Jupyter" />
          <p>Jupyter</p>
          <div className="skill-experience">3 years using Jupyter Notebooks</div>
        </div>
        
        <div className="skill-item">
          <SiApachespark size={50} title="Apache Spark" />
          <p>Apache Spark</p>
          <div className="skill-experience">1 year with big data processing</div>
        </div>
        <div className="skill-item">
          <FaReact size={50} title="React" />
          <p>React</p>
          <div className="skill-experience">2 years building UI</div>
        </div>
        
        </div>
    </div>
      );
    }

    export default Skills;