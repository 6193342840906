// src/components/AnimatedAvatar.js
import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import animationData from './avatar.json'; // Adjust the path based on your file location

function AnimatedAvatar() {
  return (
    <div className="avatar-container">
      <Player
        autoplay
        loop
        src={animationData}
        style={{ height: '40px', width: '40px' }}
      />
    </div>
  );
}

export default AnimatedAvatar;
