// src/App.js
import React from "react";
import "./App.css"; // Add your custom CSS styles here
import Header from "./Header";
import About from "./About";
import Skills from "./Skills";
import AnimatedAvatar from "./AnimatedAvatar";
import myImage from './assets/my-image.jpg';
function App() {
  return (
    <div className="App">
      <Header />
      
      <About  />
      <Skills />
      
    </div>
  );
}

export default App;
