// src/About.js
import React from "react";
import AnimatedAvatar from "./AnimatedAvatar";

function About() {
  return (
    <section className="about">
      <h2>About Me</h2>
      <p>
        Love to solve challenging problems by developing efficient algorithms and beautiful applications.
      </p>
      <AnimatedAvatar />
    </section>
    
  );
}

export default About;
